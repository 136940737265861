<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="row justify-content-between align-items-end g-3 mb-5">
                <div class="col-12 col-sm-auto col-xl-10">
                <h2 class="mb-0">Notificaciones</h2>
                <button v-if="notificaciones.length > 0" class="btn-sm btn btn-warning p-1 fs--1 fw-normal" type="button" @click="marcarTodas()">Marcar todas como leidas</button>
                <button v-if="notificaciones.length > 0" class="btn-sm btn btn-outline-warning  ms-3 p-1 fs--1 fw-normal" type="button" @click="deleteAll()">Eliminar todas mis Notificaciones</button>
                </div>
               
            </div>
            <div v-if="notificacionesHoy.length > 0">
                <h5 class="text-black mb-3">Hoy</h5>
                <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300 ">
                    <div v-for="(notas,x) in notificacionesHoy" :key="x" class="d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top " :class="[(notas.nota.leida ? 'read' : 'unread')]">
                        <div class="d-flex">
                            <Profile :usuario="{nombre: notas.afectador.nombre, apellidos: notas.afectador.apellidos, ruta_foto: notas.afectador.foto}" :size="'xl'" class="pe-3" />
                            <div class="me-3 flex-1 mt-2">
                                <h4 class="fs--1 text-black">{{notas.afectador.nombre}} {{ notas.afectador.apellidos }}</h4><At /><span>{{ notas.afectador.email }}</span>
                                <p class="fs--1 text-1000">
                                    <span class='me-1'>
                                        <TagPlusOutline class="text-success" v-if="notas.nota.tipo == 'creacion'" />
                                        <AccountTag class="text-warning" v-if="notas.nota.tipo == 'asignacion'" />
                                        <ListStatus  class="text-success"  v-if="notas.nota.tipo == 'estatus'" />
                                        <MessagePlusOutline class="text-primary"  v-if="notas.nota.tipo == 'seguimiento'" />
                                        <AttachmentPlus class="text-danger"  v-if="notas.nota.tipo == 'adjunto'" />
                                    </span>
                                    <router-link :to="'/ticket/'+notas.nota.ticket_id" @click="marcar(notas)">{{notas.nota.nota}}</router-link><span class="ms-2 text-600 fw-bold fs--2">{{formatFromNow(notas.nota.createdAt)}}</span></p>
                                <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold text-uppercase">{{formato(notas.nota.createdAt,"h:mm a")}} </span>{{formato(notas.nota.createdAt,"d MMMM,YYYY")}}</p>
                            </div>
                        </div>
                        <div class="font-sans-serif">
               
                            <EmailOpenOutline v-if="notas.nota.leida == 1" v-tooltip="'Leida'" />
                            <EmailOutline v-if="notas.nota.leida == 0" v-tooltip="'Sin Leer'" />

                            <button class="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs--2 text-900"></span></button>

                            <div  class="dropdown-menu dropdown-menu-end py-2">
                                <a class="dropdown-item" v-if="notas.nota.leida == 0" href="#" @click="marcar(notas)">Marcar como leido</a>
                                <a class="dropdown-item"  href="#" @click="deleteOne(notas.nota.id)">Eliminar</a>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
            <div v-if="notificacionesAyer.length > 0">
                <h5 class="text-semi-bold text-black mb-3">Ayer</h5>
                <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300">
                    <div v-for="(notas,x) in notificacionesAyer" :key="x" class="d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top " :class="[(notas.nota.leida ? 'read' : 'unread')]">
                        <div class="d-flex">
                            <Profile :usuario="{nombre: notas.afectador.nombre, apellidos: notas.afectador.apellidos, ruta_foto: notas.afectador.foto}" :size="'xl'" class="pe-3" />
                            <div class="me-3 flex-1 mt-2">
                                <h4 class="fs--1 text-black">{{notas.afectador.nombre}} {{ notas.afectador.apellidos }}</h4><At /><span>{{ notas.afectador.email }}</span>
                                <p class="fs--1 text-1000">
                                    <span class='me-1'>
                                        <TagPlusOutline class="text-success" v-if="notas.nota.tipo == 'creacion'" />
                                        <AccountTag class="text-warning" v-if="notas.nota.tipo == 'asignacion'" />
                                        <ListStatus  class="text-success"  v-if="notas.nota.tipo == 'estatus'" />
                                        <MessagePlusOutline class="text-primary"  v-if="notas.nota.tipo == 'seguimiento'" />
                                        <AttachmentPlus class="text-danger"  v-if="notas.nota.tipo == 'adjunto'" />
                                    </span><router-link :to="'/ticket/'+notas.nota.ticket_id" @click="marcar(notas)">{{notas.nota.nota}}</router-link><span class="ms-2 text-600 fw-bold fs--2">{{formatFromNow(notas.nota.createdAt)}}</span></p>
                                <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold text-uppercase">{{formato(notas.nota.createdAt,"h:mm a")}} </span>{{formato(notas.nota.createdAt,"d MMMM,YYYY")}}</p>
                            </div>
                        </div>
                        <div class="font-sans-serif">
                            
                            <EmailOpenOutline v-if="notas.nota.leida == 1" v-tooltip="'Leida'" />
                            <EmailOutline v-if="notas.nota.leida == 0" v-tooltip="'Sin Leer'" />
                            <button class="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs--2 text-900"></span></button>
                            <div  class="dropdown-menu dropdown-menu-end py-2">
                                <a class="dropdown-item" v-if="notas.nota.leida == 0" href="#" @click="marcar(notas)">Marcar como leido</a>
                                <a class="dropdown-item"  href="#" @click="deleteOne(notas.nota.id)">Eliminar</a>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
            <div v-if="notificacionesResto.length > 0">
                <div class="mx-n4 mx-lg-n6 mb-5 border-bottom border-300">
                    <div v-for="(notas,x) in notificacionesResto" :key="x" class="d-flex align-items-center justify-content-between py-3 border-300 px-lg-6 px-4 notification-card border-top " :class="[(notas.nota.leida ? 'read' : 'unread')]">
                        <div class="d-flex">
                            <Profile :usuario="{nombre: notas.afectador.nombre, apellidos: notas.afectador.apellidos, ruta_foto: notas.afectador.foto}" :size="'xl'" class="pe-3" />
                            <div class="me-3 flex-1 mt-2">
                                <h4 class="fs--1 text-black">{{notas.afectador.nombre}} {{ notas.afectador.apellidos }}</h4><At /><span>{{ notas.afectador.email }}</span>
                                <p class="fs--1 text-1000">
                                    <span class='me-1'>
                                        <TagPlusOutline class="text-success" v-if="notas.nota.tipo == 'creacion'" />
                                        <AccountTag class="text-warning" v-if="notas.nota.tipo == 'asignacion'" />
                                        <ListStatus  class="text-success"  v-if="notas.nota.tipo == 'estatus'" />
                                        <MessagePlusOutline class="text-primary"  v-if="notas.nota.tipo == 'seguimiento'" />
                                        <AttachmentPlus class="text-danger"  v-if="notas.nota.tipo == 'adjunto'" />
                                    </span><router-link :to="'/ticket/'+notas.nota.ticket_id" @click="marcar(notas)">{{notas.nota.nota}}</router-link><span class="ms-2 text-600 fw-bold fs--2">{{formatFromNow(notas.nota.createdAt)}}</span></p>
                                <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold text-uppercase">{{formato(notas.nota.createdAt,"h:mm a")}} </span>{{formato(notas.nota.createdAt,"d MMMM,YYYY")}}</p>
                            </div>
                        </div>
                        <div class="font-sans-serif">
                            
                            <EmailOpenOutline v-if="notas.nota.leida == 1" v-tooltip="'Leida'" />
                            <EmailOutline v-if="notas.nota.leida == 0" v-tooltip="'Sin Leer'" />
                            <button class="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs--2 text-900"></span></button>
                            <div  class="dropdown-menu dropdown-menu-end py-2">
                                <a class="dropdown-item" v-if="notas.nota.leida == 0" href="#" @click="marcar(notas)">Marcar como leido</a>
                                <a class="dropdown-item"  href="#" @click="deleteOne(notas.nota.id)">Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="notificaciones.length < 1">
                <div class="p-5 m-auto text-center w-50">
                    <p>No tienes notificaciones de soporte por el momento.</p>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import handleGlobals from '@/mixins/handleGlobals';
import axios from 'axios'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import AccountTag from 'vue-material-design-icons/AccountTag.vue';
import AttachmentPlus from 'vue-material-design-icons/AttachmentPlus.vue';
import ListStatus from 'vue-material-design-icons/ListStatus.vue';
import MessagePlusOutline from 'vue-material-design-icons/MessagePlusOutline.vue';
import At from 'vue-material-design-icons/At.vue';
import TagPlusOutline from 'vue-material-design-icons/TagPlusOutline.vue';
import EmailOutline from 'vue-material-design-icons/EmailOutline.vue';
import EmailOpenOutline from 'vue-material-design-icons/EmailOpenOutline.vue';
import moment from 'moment'

import { defineAsyncComponent } from 'vue'

export default {
    name: 'NotificacionesView',
    components:{Profile: defineAsyncComponent(() =>
            import('@/components/Profile.vue')
        ),SideBar,TopBar,MagnifyIcon,AccountTag,AttachmentPlus,ListStatus,MessagePlusOutline,TagPlusOutline,
        At,EmailOutline,EmailOpenOutline},
    mixins:[handleGlobals],
    data(){
        return{
            notificaciones:[],
            
        }
    },
    created(){
        this.getData()
    },
    computed:{
        notificacionesHoy(){
            let hoy = new Date()
            let notes = this.notificaciones.filter((n)=>{
                return moment(n.nota.createdAt).format('YYYY-MM-DD') ==  moment(hoy).format('YYYY-MM-DD')
            })
            return notes
        },
        notificacionesAyer(){
            let ayer = new Date()
            let notes = this.notificaciones.filter((n)=>{
                return moment(n.nota.createdAt).format('YYYY-MM-DD') == moment(ayer).subtract(1, 'days').format('YYYY-MM-DD') 
            })
            return notes
        },
        notificacionesResto(){
            let ayer = new Date()
            let notes = this.notificaciones.filter((n)=>{
                return moment(n.nota.createdAt).format('YYYY-MM-DD') < moment(ayer).subtract(1, 'days').format('YYYY-MM-DD') 
            })
            return notes
        }
    },
    methods:{
        marcarTodas(){
            axios.post(this.$url+'/notificaciones/admin-marcar-todas',{},{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.getData()
            }).catch((err)=>{
                console.log(err)
            })
        },
        marcar(nota){
            axios.post(this.$url+'/notificaciones/admin-marcar/'+nota.nota.id,{},{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then(()=>{
                this.getData()
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                console.log(err)
            })
        },
        formatFromNow(fecha){
            return moment(fecha).fromNow()
        },
        getData(){
            axios.get(this.$url+'/notificaciones/admin-all',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.notificaciones = res.data.notificaciones
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                console.log(err)
            })
        },
        deleteOne(id){
            axios.put(this.$url+'/notificaciones/delete/admin/'+id,{},{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
               if(res.status == 200){
                    this.getData()
               }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                });
            })
        },
        deleteAll(){
            axios.put(this.$url+'/notificaciones/delete/admin-todas',{},{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.status == 200){
                    this.getData()
               }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                });
            })
        }
    }
  }
  </script>
  