import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from 'vue-notification'
import VuePaginate from 'vue-paginate'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import { Buffer } from 'buffer';

window.Buffer = Buffer;
Vue.use(FloatingVue)

Vue.config.productionTip = false
Vue.prototype.$url = process.env.VUE_APP_SERVER_URL //variable global

Vue.prototype.$token = localStorage.getItem('stkn')  //variable global

Vue.use(VuePaginate)
Vue.use(Notifications)

Vue.filter('leadingZeros', function (num,size) {
  let tnum = (num != 0 && num != undefined) ? num.toString() : '';
  while (tnum.length < size) tnum = "0" + tnum;
  return (num == 0 ? '' : tnum)
  /* use 
  <td class="text-right">
    {{ invoice.fees | leadingZeros(3) }}
  </td>*/
  
});

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
