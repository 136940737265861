<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent = "validar()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                        <h2 class="mb-0" v-if="modo == 'Creando'">Nuevo Estatus</h2>
                        <h2 class="mb-0" v-else >Modificando Estatus</h2>
                        <div class="text-end">
                            <router-link to="/estatus" class="btn btn-outline-primary text-nowrap">Regresar</router-link>
                        </div>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                    <div class="d-flex">
                        <button type="submit" class="btn btn-primary w-100 text-nowrap">Publicar Estatus</button>
                    </div>
                    </div>
                    <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input class="form-control" v-model="estatus.nombre" type="text" placeholder="nombre del estatus" />
                                    <label>Nombre</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="form-floating">
                                    <select class="form-select" v-model="estatus.icono">
                                        <option disabled selected="selected">Selecciona...</option>
                                        <option value="AlertCircleOutline"><AlertCircleOutlineIcon /> AlertCircleOutline</option>
                                        <option value="CircleEditOutline"><CircleEditOutlineIcon /> CircleEditOutline</option>
                                        <option value="StarCheck" ><StarCheckIcon /> StarCheck</option>
                                        <option value="CheckCircleOutline"><CheckCircleOutlineIcon /> CheckCircleOutline</option>
                                        <option value="ProgressCheck" ><ProgressCheckIcon /> ProgressCheck</option>
                                        <option value="Cancel" ><CancelIcon /> Cancel</option>
                                    </select>
                                    <label >Icono</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="form-floating">
                                    <select class="form-select" v-model="estatus.color">
                                        <option disabled selected="selected">Selecciona...</option>
                                        <option value="primary" class="text-primary">Azul</option>
                                        <option value="info" class="text-primary">Celeste</option>
                                        <option value="success" class="text-success">Verde</option>
                                        <option value="warning" class="text-warning">Naranja</option>
                                        <option value="danger" class="text-danger">Rojo</option>
                                        <option value="secondary" class="text-secondary">Negro</option>
                                    </select>
                                    <label >Color</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="form-floating">
                                    <input class="form-control" v-model="estatus.orden" type="number" />
                                    <label>Orden</label>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';
  import ProgressClockIcon from 'vue-material-design-icons/ProgressClock.vue';
  import ProgressCheckIcon from 'vue-material-design-icons/ProgressCheck.vue';
  import CircleEditOutlineIcon from 'vue-material-design-icons/CircleEditOutline.vue';
  import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
  import CancelIcon from 'vue-material-design-icons/Cancel.vue';
  import StarCheckIcon from 'vue-material-design-icons/StarCheck.vue';
  import handleGlobals from '@/mixins/handleGlobals';
 
  export default {
    name: 'CrearEstatusView',
    mixins:[handleGlobals],
    components:{SideBar,TopBar,AlertCircleOutlineIcon,ProgressClockIcon,ProgressCheckIcon,CircleEditOutlineIcon,CheckCircleOutlineIcon,CancelIcon,StarCheckIcon},
    data(){
        return{
            estatus:{},
            msg_err:'',
            modo: (this.$route.params.id > 0 ? 'Modificando' : 'Creando'),
        }
    },
    created(){
        if(this.modo == 'Modificando') this.getData()
    },
    methods:{
        validar(){
            if(!this.estatus.nombre){
                this.$notify({
                    group: 'foo',
                    title: 'Important message',
                    text: 'Hello user! This is a notification!',
                    type: 'danger'
                });
            }else{
                this.setEstatus()
            }
        },
        setEstatus(){
            axios.post(this.$url+(this.modo == 'Creando' ? '/estatus' : '/estatus/'+this.$route.params.id),this.estatus,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.$router.push({name: 'estatus'})
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getData(){
            axios.get(this.$url+'/estatus/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.estatus = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        }
    }
  }
  </script>
  