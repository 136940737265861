import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('stkn'),
    filtro: localStorage.getItem('filtro'),
  },
  getters: {
  },
  mutations: {
    setToken(state,token){
      state.token = token
    },
    setFiltro(state,filtro){
      state.filtro = filtro
    },
  },
  actions: {
    saveToken({commit},token){
      commit('setToken',token)
      localStorage.setItem('stkn',token)
    },
    saveFiltro({commit},filtro){
      commit('setFiltro',filtro)
      localStorage.setItem('filtro',filtro)
    },
    logout({commit}){
      commit('setToken',null)
      localStorage.clear()
    }

  },
  modules: {
  }
})
