<template>
  <nav class="navbar navbar-top navbar-expand-lg" id="navbarCombo" data-navbar-top="combo" data-move-target="#navbarVerticalNav" >
    <div class="navbar-logo">
        <button class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>
        <a class="navbar-brand me-1 me-sm-3" href="/">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center"><img :src="basePath+'assets/img/logo.png'" alt="IASC" width="27" />
              <p class="logo-text ms-2 d-none d-sm-block">IASC</p>
            </div>
          </div>
        </a>
      </div>
      
      <div class="collapse navbar-collapse navbar-top-collapse order-1 order-lg-0 justify-content-center" id="navbarTopCollapse">
        
      </div>
      <ul class="navbar-nav navbar-nav-icons flex-row">
        <li class="nav-item">
          <div class="theme-control-toggle fa-icon-wait px-2">
            <input class="form-check-input ms-0 theme-control-toggle-input" type="checkbox" data-theme-control="iascTheme" value="dark" id="themeControlToggle" />
            <label class="mb-0 theme-control-toggle-label theme-control-toggle-light" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left"  title="Cambiar tema"><WeatherNightIcon /></label>
            <label class="mb-0 theme-control-toggle-label theme-control-toggle-dark" for="themeControlToggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Cambiar tema"><WeatherSunnyIcon /></label>
          </div>
        </li>
        <li v-if="routePath != '/notificaciones'" class="nav-item dropdown">
          <a class="nav-link position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="outside"><BellOutlineIcon style="height:20px;width:20px;" /> <span v-if="nuevosMensajes > 0" class="position-absolute top-2 translate-middle badge rounded-pill bg-danger-500">{{(nuevosMensajes > 100) ? '99+' : nuevosMensajes}}<span class="visually-hidden">nuevos mensajes</span></span></a>
          <div class="dropdown-menu dropdown-menu-end notification-dropdown-menu py-0 shadow border border-300 navbar-dropdown-caret" id="navbarDropdownNotfication" aria-labelledby="navbarDropdownNotfication">
            <div class="card position-relative border-0">
              <div class="card-header p-2">
                <div class="d-flex justify-content-between">
                  <h5 class="text-black mb-0">Notificaciones</h5>
                  <button v-if="notificaciones.length > 0" class="btn btn-link p-0 fs--1 fw-normal" type="button" @click="marcarTodas()">Marcar todas como leidas</button>
                </div>
              </div>
              
              <div class="card-body p-0" v-if="notificaciones.length > 0">
                <div class="scrollbar-overlay" style="height: 27rem;">
                  <div class="border-300">
                    <div v-for="(notificacion,z) in notificaciones" :key="z" class="px-2 px-sm-3 py-3 border-300 notification-card position-relative  border-bottom" :class="[(notificacion.nota.leida ? 'read' : 'unread')]">
                      <div class="d-flex align-items-center justify-content-between position-relative">
                        <div class="d-flex">
                          <Profile class="status-online me-3" :usuario="{nombre: notificacion.afectador.nombre, apellidos: notificacion.afectador.apellidos, ruta_foto: notificacion.afectador.foto}" :size="'m'" />
                         
                          <div class="flex-1 me-sm-3">
                            <h4 class="fs--1 text-black">{{notificacion.afectador.nombre}} {{ notificacion.afectador.apellidos }}</h4>
                            <p class="fs--1 text-1000 mb-2 mb-sm-3 fw-normal">
                              <TagPlusOutline class="text-success" v-if="notificacion.nota.tipo == 'creacion'" />
                              <AccountTag class="text-warning" v-if="notificacion.nota.tipo == 'asignacion'" />
                              <ListStatus  class="text-success"  v-if="notificacion.nota.tipo == 'estatus'" />
                              <MessagePlusOutline class="text-primary"  v-if="notificacion.nota.tipo == 'seguimiento'" />
                              <AttachmentPlus class="text-danger"  v-if="notificacion.nota.tipo == 'adjunto'" />
                              <router-link :to="'/ticket/'+notificacion.nota.ticket_id" @click="marcar(notificacion.nota)">{{notificacion.nota.nota}}</router-link><span class="ms-2 text-400 fw-bold fs--2">{{formatFromNow(notificacion.nota.createdAt)}}</span></p>
                            <p class="text-800 fs--1 mb-0"><span class="me-1 fas fa-clock"></span><span class="fw-bold">{{formato(notificacion.nota.createdAt,"h:mm a")}} </span>{{formato(notificacion.nota.createdAt,"DD MMM,YYYY")}}</p>
                          </div>
                        </div>
                       
                        <div class="font-sans-serif d-none d-sm-block">
                          <EmailOpenOutline v-if="notificacion.nota.leida"  />
                          <EmailOutline v-if="!notificacion.nota.leida" />
                          <button  v-if="!notificacion.nota.leida" class="btn fs--2 btn-sm dropdown-toggle dropdown-caret-none transition-none notification-dropdown-toggle" type="button" data-stop-propagation="data-stop-propagation" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span class="fas fa-ellipsis-h fs--2 text-900"></span></button>
                          <div  v-if="!notificacion.nota.leida" class="dropdown-menu dropdown-menu-end py-2"><a class="dropdown-item" href="#" @click="marcar(notificacion)">Marcar como leido</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0" v-else>
                <p class="text-center p-4">NO TIENES NOTIFICACIONES</p>
              </div>
              <div class="card-footer p-0 border-top border-0">
                <div v-if="notificaciones.length > 0" class="my-2 text-center fw-bold fs--2 text-600"><router-link class="fw-bolder" to="/notificaciones">Ver todas las notificaciones</router-link></div>
              </div>
            </div>
          </div>
        </li>
       
        <li class="nav-item dropdown">
          <a class="nav-link lh-1 pe-0" id="navbarDropdownUser" href="#!" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
            <Profile :usuario="user" :size="'l'"  />
          </a>
          
          <div class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300" aria-labelledby="navbarDropdownUser">
            <div class="card position-relative border-0">
              <div class="card-body p-0">
                <div class="text-center pt-4 pb-3">
                  <label  for="foto-perfil"><Profile :usuario="user" :size="'xl'" style="cursor:pointer" /></label>
                  <input type="file" class="d-none" id="foto-perfil" @change="uploadProfile($event)">
                 
                  <h6 class="mt-2 text-black">{{user.nombre}}</h6>
                  <h6 class="mt-2 text-black"><small>{{user.email}}</small></h6>
                  <h6 class="mt-2 text-black">{{user.empresa}}</h6>
                  
                </div>  
              </div>
             
              <div class="card-footer p-0 ">
                <hr />
                <div class="px-3">
                  <a class="btn btn-iasc-secondary d-flex flex-center w-100" href="#" @click="logout()">
                    <span class="me-2" data-feather="log-out"> </span>Cerrar Sesión
                  </a>
                </div>
                <div class="my-2 text-center fw-bold fs--2 text-600"><a class="text-600 me-1" href="#!">Privacy policy</a>&bull;<a class="text-600 mx-1" href="#!">Terms</a>&bull;<a class="text-600 ms-1" href="#!">Cookies</a></div>
              </div>
            </div>
          </div>
        </li>
      </ul>

  </nav>
</template>


<script>
import axios from 'axios'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import DotsGridIcon from 'vue-material-design-icons/DotsGrid.vue';
import WeatherSunnyIcon from 'vue-material-design-icons/WeatherSunny.vue';
import WeatherNightIcon from 'vue-material-design-icons/WeatherNight.vue';
import BellOutlineIcon from 'vue-material-design-icons/BellOutline.vue';
import TagMultipleOutlineIcon from 'vue-material-design-icons/TagMultipleOutline.vue';
import Profile from '@/components/Profile.vue'
import handleGlobals from '@/mixins/handleGlobals';

import AccountTag from 'vue-material-design-icons/AccountTag.vue';
import AttachmentPlus from 'vue-material-design-icons/AttachmentPlus.vue';
import ListStatus from 'vue-material-design-icons/ListStatus.vue';
import MessagePlusOutline from 'vue-material-design-icons/MessagePlusOutline.vue';
import EmailOutline from 'vue-material-design-icons/EmailOutline.vue';
import EmailOpenOutline from 'vue-material-design-icons/EmailOpenOutline.vue';

import moment from 'moment'
export default {
  name: 'SideBarNav',
  mixins:[handleGlobals],
  components:{MagnifyIcon,EmailOutline,EmailOpenOutline,Profile,DotsGridIcon,WeatherSunnyIcon,WeatherNightIcon,BellOutlineIcon,TagMultipleOutlineIcon,AccountTag,MessagePlusOutline,AttachmentPlus,ListStatus},
  data(){
    return{
      user: JSON.parse(Buffer.from(this.$store.state.token.split('.')[1], 'base64').toString()),
      notificaciones: [],
      nuevosMensajes: 0
    }
  },
  created(){
    this.getNotificaciones()
  },
  computed:{
     routePath(){
       return this.$route.path 
    },
    basePath(){
        return process.env.VUE_APP_BASE_SUBFOLDER
    },
  },
  methods:{
      getNotificaciones(){
        axios.get(this.$url+'/notificaciones/findtopbar',{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : this.$store.state.token
            }
        })
        .then((res)=>{
            this.notificaciones = res.data.notificaciones
            this.nuevosMensajes = res.data.nuevosMensajes

        }).catch((err)=>{
          if(err.response) {
            if(err.response.status == 500) this.logout()
          }
        })
      },
      formatFromNow(fecha){
          return moment(fecha).fromNow()
      },
      marcarTodas(){
      axios.post(this.$url+'/notificaciones/admin-marcar-todas',{},{
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : this.$token
            }
        })
        .then((res)=>{
         
          this.getNotificaciones()
          
        }).catch((err)=>{
          if(err.response) {
            if(err.response.status == 500) this.logout()
          }
        })
      },
      marcar(nota){
          axios.post(this.$url+'/notificaciones/admin-marcar/'+nota.nota.id,{leida:nota.nota.leida},{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization' : this.$token
              }
          })
          .then((res)=>{
            this.getNotificaciones()
          }).catch((err)=>{
            if(err.response) {
              if(err.response.status == 500) this.logout()
            }
          })
      },
      uploadProfile($event){
        var image 
        if($event.target.files.length >= 1){
            image =  $event.target.files[0]
        }

        if(image.size <= 2000000){
          if( image.type == 'image/jpg' || image.type == 'image/jpeg' || image.type == 'image/png'){
            this.user.foto = URL.createObjectURL(image)

            let fm = new FormData()
            fm.append('foto',image) 
          
            axios.post(this.$url+'/set-profile',fm,{
                headers: {
                    'Content-Type' : 'multipart/form-data',
                    'Authorization' : this.$token
                }
            }).then((res)=>{
              if(res.data.message){
                      this.$notify({
                          group: 'iasc',
                          title: 'Error',
                          text: res.data.message,
                          type: 'error'
                      });
              }else{
                this.$store.dispatch('saveToken',res.data.token)
              }
            }).catch((err)=>{
                  if(err.response) {
                      if(err.response.status == 500) this.logout()
                  }
                  this.$notify({
                      group: 'iasc',
                      title: 'Error',
                      text: err.response.data.message,
                      type: 'error'
                  });
              })
          }else{
              this.$notify({
                  group: 'iasc',
                  title: 'Error',
                  text: 'El tipo de archivo no se permite',
                  type: 'error'
              });
          }
      }else{
          this.$notify({
              group: 'iasc',
              title: 'Error',
              text: 'El archivo debe pesar menos de 2Mb',
              type: 'error'
          });
      }

      }
    }
  }
</script>