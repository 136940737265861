<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
           
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                    <h2 class="mb-0">Sistemas</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                        <div class="d-flex">
                            <router-link to="/nuevo-sistema" class="btn btn-primary w-100 text-nowrap">Nuevo Sistema</router-link>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="card" id="table">
                                 <div class="card-header">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <form>
                                                <div class="input-group input-group-flush input-group-merge input-group-reverse">
                                                    <span class="input-group-text search"><MagnifyIcon /></span>
                                                    <input class="form-control list-search" v-model="filtro" type="buscar" placeholder="Buscar..">
                                                </div>
                                            </form>
                                        </div>
                                    </div> 
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-sm fs--1 mb-0">
                                        <thead>
                                            <tr>
                                                <th class="sort border-top" data-sort="item-id">Id</th>
                                                <th class="sort border-top" data-sort="item-nombre">Nombre</th>
                                                <th class="sort border-top" data-sort="item-desarrollo">Desarrollo</th>
                                                <th class="sort border-top" data-sort="item-activado">Activo</th>
                                                <th class="sort border-top" data-sort="item-bloqueado">Bloqueado</th>
                                                <th class="sort text-end align-middle pe-0 border-top" scope="col">
                                                    <span class="nav-link-icon"><span data-feather="cog"></span></span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(sistema,x) in filteredSistemas" :key="x">
                                                <td class="item-id align-middle">{{sistema.id | leadingZeros(3)}}</td>
                                                <td class="item-nombre align-middle">{{sistema.nombre}}</td>
                                                <td class="item-desarrolo align-middle">{{sistema.desarrollo}}</td>
                                                <td class="item-activado align-middle">
                                                    <span class="badge badge-iasc badge-iasc-success" v-if="sistema.activado">Activo</span>
                                                    <span class="badge badge-iasc badge-iasc-danger" v-else>Inactivo</span>
                                                </td>
                                                <td class="item-bloqueado align-middle">
                                                    <span class="badge badge-iasc badge-iasc-danger" v-if="sistema.bloquead == 1">Bloqueado</span>
                                                    <span class="badge badge-iasc badge-iasc-success" v-else>Desbloqueado</span>
                                                </td>
                                                <td class="align-middle white-space-nowrap text-end pe-0">
                                                    <div class="font-sans-serif btn-reveal-trigger position-static">
                                                        <button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><svg class="svg-inline--fa fa-ellipsis fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path></svg><!-- <span class="fas fa-ellipsis-h fs--2"></span> Font Awesome fontawesome.com --></button>
                                                        <div class="dropdown-menu dropdown-menu-end py-2">
                                                            <router-link class="dropdown-item" :to="'/sistema/'+sistema.id">Abrir</router-link>
                                                        <div class="dropdown-divider"></div>
                                                            <a href="#" class="dropdown-item text-danger" @click="borrar('sistemas',sistema.id)">Borrar</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                               
                                <div class="d-flex flex-between-center pt-3">
                                   
                                    <p class="mb-0 fs--1">
                                        <span class="d-none d-sm-inline-block" data-list-info="data-list-info">Mostrando {{filteredSistemas.length}} de {{ sistemas.length }} </span>
                                        <span class="d-none d-sm-inline-block"> &mdash; </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import handleGlobals from '@/mixins/handleGlobals';
import axios from 'axios'

  export default {
    name: 'SistemasView',
    components:{SideBar,TopBar,MagnifyIcon},
    mixins:[handleGlobals],
    data(){
        return{
            sistemas:[],
            filtro:''
        }
    },
    created(){
        this.getData()
    },
    computed:{
        filteredSistemas(){
            let res = this.sistemas.filter((e)=>{
                if(this.filtro != ''){
                    return ((e.nombre) ? e.nombre.toLowerCase().includes(this.filtro.toLowerCase()) : '') || 
                           ((e.desarrollo) ? e.desarrollo.toLowerCase().includes(this.filtro.toLowerCase()) : '')
                }else{
                    return e
                }
            })
            return res
        }
    },
    methods:{
        getData(){
             axios.get(this.$url+'/sistemas',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.sistemas = res.data

            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        
    }
  }
  </script>
  