<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="mb-9">
            <div id="projectSummary" >
              <div class="row mb-4 gx-6 gy-3 align-items-center">
                <div class="px-3">
                  <div class="row justify-content-between mb-6">
                    <div v-for="(est,e) in estatuses" :key="e" class="col-6 col-md-4 col-xxl-2 text-center border-start-xxl border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pb-xxl-0 ">
                      <span class="uil fs-3 lh-1" :class="['text-'+est.color]"><component :is="est.icono+'Icon'" /></span>
                      <h1 class="fs-3 pt-3">{{est.cantidad}}</h1>
                      <p class="fs--1 mb-0">{{est.nombre}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <h2 class="mb-0">Tickets<span class="fw-normal text-700 ms-3">({{totalTickets}})</span></h2>
                </div>
                <div class="col-auto">
                  <router-link class="btn btn-primary px-5" to="/nuevo-ticket"><i class="fa-solid fa-plus me-2"></i>Agregar ticket</router-link></div>
              </div>
              
              <div class="table-responsive scrollbar">
                <table class="table fs--1 mb-0 border-top border-200">
                  <thead>
                    <tr>
                      <th class="sort white-space-nowrap align-middle ps-0" scope="col" data-sort="projectName" style="width:30%;">CLIENTES</th>
                      <th class="sort align-middle ps-3" scope="col" data-sort="assigness" style="width:10%;">USUARIOS</th>
                      <th class="sort align-middle ps-3" scope="col" data-sort="start" style="width:10%;">PENDIENTES</th>
                      <th class="sort align-middle ps-3" scope="col" data-sort="deadline" style="width:15%;">TERMINADOS</th>
                      <th class="sort align-middle ps-3" scope="col" data-sort="task" style="width:12%;">TOTAL</th>
                      <th class="sort align-middle ps-3" scope="col" data-sort="projectprogress" style="width:5%;">PROGRESO</th>
                      <th class="sort align-middle text-end" scope="col" style="width:10%;"></th>
                    </tr>
                  </thead>
                  <tbody class="list" id="project-list-table-body">
                    <tr class="position-static" v-for="(dato,x) in avances" :key="x">
                      <td class="align-middle time white-space-nowrap ps-0 projectName py-4">
                        <a class="text-decoration-none fw-bold fs-0" href="#!" @click="saveFiltro(dato.nombre)">{{dato.nombre}}</a>
                      </td>
                      <td class="align-middle white-space-nowrap assigness ps-3 py-4">
                        <div class="avatar-group avatar-group-dense">
                          <Profile v-for="(usuario,z) in dato.clientes" :key="z" :usuario="usuario" :size="'s'" />
                          <div class="avatar avatar-s  rounded-circle" v-if="dato.clientes.length > 5">
                            <div class="avatar-name rounded-circle "><span>+2</span></div>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle white-space-nowrap start ps-3 py-4">
                        <p class="mb-0 fs--1 text-900">{{dato.pendientes}}</p>
                      </td>
                      <td class="align-middle white-space-nowrap deadline ps-3 py-4">
                        <p class="mb-0 fs--1 text-900">{{dato.terminados}}</p>
                      </td>
                      <td class="align-middle white-space-nowrap task ps-3 py-4">
                        <p class="fw-bo text-900 fs--1 mb-0">{{dato.pendientes + dato.terminados}}</p>
                      </td>
                      <td class="align-middle white-space-nowrap ps-3 projectprogress">
                        <p class="text-800 fs--2 mb-0">{{dato.terminados}} / {{dato.terminados + dato.pendientes}}</p>
                        <div class="progress" style="height:3px;">
                          <div class="progress-bar bg-success" :style="{'width': getPercentage(dato) +'%'}" role="progressbar" :aria-valuenow="getPercentage(dato)" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  
  import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';
  import ProgressClockIcon from 'vue-material-design-icons/ProgressClock.vue';
  import ProgressCheckIcon from 'vue-material-design-icons/ProgressCheck.vue';
  import CircleEditOutlineIcon from 'vue-material-design-icons/CircleEditOutline.vue';
  import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
  import CancelIcon from 'vue-material-design-icons/Cancel.vue';
  import StarCheckIcon from 'vue-material-design-icons/StarCheck.vue';
  import Profile from '@/components/Profile.vue';
  export default {
    name: 'avancesView',
    components:{SideBar,TopBar,Profile,AlertCircleOutlineIcon,ProgressClockIcon,ProgressCheckIcon,CircleEditOutlineIcon,CheckCircleOutlineIcon,CancelIcon,StarCheckIcon},
    data(){
        return{
            filtro:'',
            avances:[],
            estatuses:[],
            totalTickets:0
        }
    },
    created(){
        this.getData()
    },
    methods:{
      getPercentage(dato){
        if((dato.terminados + dato.pendientes) > 0 && dato.pendientes > 0){
          return Math.floor(((dato.terminados + dato.pendientes) * 25) / dato.pendientes )
        }else{
          return 0
        }
      },
      saveFiltro(nombre){
        this.$store.dispatch('saveFiltro',nombre)
        this.$router.push({name:'tickets'})
      },
      getData(){
            axios.get(this.$url+'/avances',{
              headers: {
                  'Content-Type' : 'application/json',
                  'Authorization' : this.$store.state.token
              }
          })
          .then((res)=>{
            if(res.status == 500) this.logout()
              this.avances = res.data.empresas
              this.estatuses = res.data.estatus
              this.totalTickets = res.data.totalTickets

          }).catch((err)=>{
            if(err.response) {
              if(err.response.status == 500) this.logout()
            }
            this.$notify({
                group: 'iasc',
                title: 'Error',
                text: err.response.data.message,
                type: 'error'
            });
          })
      }
    }
  }
  </script>
  