<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                        <h2 class="mb-0" v-if="modo == 'Creando'">Nuevo Operador</h2>
                        <h2 class="mb-0" v-else >Modificando Operador</h2>

                        <div class="text-end">
                            <router-link to="/operadores" class="btn btn-outline-primary text-nowrap">Regresar</router-link>
                        </div>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                        <div class="d-flex">
                            <button @click="validar()" type="button" class="btn btn-primary  w-100 text-nowrap">Publicar Operador</button>
                        </div>
                    </div>
                    <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-8">
                        <div class="row gx-3 gy-4">
                            
                            <div class="col-sm-12 col-md-5">
                                <div class="form-floating">
                                    <input class="form-control" v-model="usuario.nombre" type="text" />
                                    <label for="floatingEventInput">Nombre</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="form-floating">
                                    <input class="form-control" v-model="usuario.apellidos" type="text" />
                                    <label for="floatingEventInput">Apellidos</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12">
                                <div class="form-floating">
                                    <input class="form-control" v-model="usuario.alias" type="text"  />
                                    <label for="floatingEventInput">Alias</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="usuario.email" type="text"  />
                                    <label for="floatingEventInput">Email</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="usuario.new_password" type="password"  />
                                    <label for="floatingEventInput">Nueva Contraseña</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  
  export default {
    name: 'CrearUsuarioView',
    components:{SideBar,TopBar},
    data(){
        return{
            usuario:{},
            msg_err:'',
            modo: (this.$route.params.id > 0 ? 'Modificando' : 'Creando'),
        }
    },
    created(){
        this.getReqs()
        if(this.modo == 'Modificando') this.getData()
    },
    methods:{
        getReqs(){
            axios.get(this.$url+'/usuarios/findreqs/',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.roles= res.data.roles
                    this.empresas = res.data.empresas
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        validar(){
            if(!this.usuario.nombre && !this.usuario.email){
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'Debe agregar un nombre y un email al Usuario',
                        type: 'error'
                    });
            }else{
                this.setUsuario()
            }
        },
        setUsuario(){
           
            axios.post(this.$url+(this.modo == 'Creando' ? '/usuario' : '/usuario/'+this.$route.params.id),this.usuario,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.$router.push({name: 'operadores'})
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getData(){
            axios.get(this.$url+'/usuario/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.usuario = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        }
    }
  }
  </script>
  