import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import jwt_decode from 'jwt-decode'

import LoginView from '@/views/LoginView.vue'

import EmpresasView from '@/views/Empresas/EmpresasView.vue'
import CrearEmpresaView from '@/views/Empresas/CrearEmpresaView.vue'

import RolesView from '@/views/Roles/RolesView.vue'
import CrearRolView from '@/views/Roles/CrearRolView.vue'

import SistemasView from '@/views/Sistemas/SistemasView.vue'
import CrearSistemaView from '@/views/Sistemas/CrearSistemaView.vue'

import TicketsView from '@/views/Tickets/TicketsView.vue'
import CrearTicketView from '@/views/Tickets/CrearTicketView.vue'
import VerTicketView from '@/views/Tickets/VerTicketView.vue'

import TiposView from '@/views/Tipos/TiposView.vue'
import CrearTipoView from '@/views/Tipos/CrearTipoView.vue'

import UsuariosView from '@/views/Usuarios/UsuariosView.vue'
import CrearUsuarioView from '@/views/Usuarios/CrearUsuarioView.vue'

import ClientesView from '@/views/Clientes/ClientesView.vue'
import CrearClienteView from '@/views/Clientes/CrearClienteView.vue'

import CategoriasView from '@/views/Categorias/CategoriasView.vue'
import CrearCategoriaView from '@/views/Categorias/CrearCategoriaView.vue'

import PrioridadesView from '@/views/Prioridades/PrioridadesView.vue'
import CrearPrioridadView from '@/views/Prioridades/CrearPrioridadView.vue'

import EstatusView from '@/views/Estatus/EstatusView.vue'
import CrearEstatusView from '@/views/Estatus/CrearEstatusView.vue'

import AvisosView from '@/views/Avisos/AvisosView.vue'
import CrearAvisoView from '@/views/Avisos/CrearAvisoView.vue'

import AvancesView from '@/views/Avances/AvancesView'
import NotificacionesView from '@/views/Notificaciones/NotificacionesView'

Vue.use(VueRouter)

const routes = [
  {path: '/login',name: 'login',component: LoginView, meta: {requiresAuth: false}},
  
  {path: '/avisos',name: 'avisos',component: AvisosView, meta: {requiresAuth: true}},
  {path: '/sistemas',name: 'sistemas',component: SistemasView, meta: {requiresAuth: true}},
  {path: '/usuarios',name: 'usuarios',component: ClientesView, meta: {requiresAuth: true}},
  {path: '/operadores',name: 'operadores',component: UsuariosView, meta: {requiresAuth: true}},
  {path: '/clientes',name: 'clientes',component: EmpresasView, meta: {requiresAuth: true}},
  {path: '/estatus',name: 'estatus',component: EstatusView, meta: {requiresAuth: true}},
  {path: '/roles',name: 'roles',component: RolesView, meta: {requiresAuth: true}},
  {path: '/categorias',name: 'categorias',component: CategoriasView, meta: {requiresAuth: true}},
  {path: '/tipos',name: 'tipos',component: TiposView, meta: {requiresAuth: true}},
  {path: '/tickets',name: 'tickets',component: TicketsView, meta: {requiresAuth: true}},
  {path: '/prioridades',name: 'prioridades',component: PrioridadesView, meta: {requiresAuth: true}},
  {path: '/avances',name: 'avances',component: AvancesView, meta: {requiresAuth: true}},
  {path: '/notificaciones',name: 'notificaciones',component: NotificacionesView, meta: {requiresAuth: true}},

  //abrir forma edita/agregar
  {path: '/aviso/:id'    ,name: 'editAviso'    ,component: CrearAvisoView, meta: {requiresAuth: true}},
  {path: '/prioridad/:id' ,name: 'editPrioridad',component: CrearPrioridadView, meta: {requiresAuth: true}},
  {path: '/categoria/:id' ,name: 'editCategoria',component: CrearCategoriaView, meta: {requiresAuth: true}},
  {path: '/cliente/:id'   ,name: 'editEmpresa'  ,component: CrearEmpresaView, meta: {requiresAuth: true}},
  {path: '/tipo/:id'      ,name: 'editTipo'     ,component: CrearTipoView, meta: {requiresAuth: true}},
  {path: '/usuario/:id'   ,name: 'editCliente'  ,component: CrearClienteView, meta: {requiresAuth: true}},
  {path: '/operador/:id'   ,name: 'editUsuario'  ,component: CrearUsuarioView, meta: {requiresAuth: true}},
  {path: '/sistema/:id'   ,name: 'editSistemas' ,component: CrearSistemaView, meta: {requiresAuth: true}},
  {path: '/ticket/modificar/:id'    ,name: 'editTicket'   ,component: CrearTicketView, meta: {requiresAuth: true}},
  {path: '/estatus/:id'   ,name: 'editEstatus'  ,component: CrearEstatusView, meta: {requiresAuth: true}},
  {path: '/rol/:id'       ,name: 'editRol'      ,component: CrearRolView, meta: {requiresAuth: true}},

 //abrir forma 
  {path: '/ticket/:id'    ,name: 'verTicket'   ,component: VerTicketView, meta: {requiresAuth: true}},

 //abrir forma edita/agregar
  {path: '/nuevo-aviso',name: 'crearAviso',component: CrearAvisoView, meta: {requiresAuth: true}},
  {path: '/nueva-prioridad',name: 'crearPrioridad',component: CrearPrioridadView, meta: {requiresAuth: true}},
  {path: '/nueva-categoria',name: 'crearCategoria',component: CrearCategoriaView, meta: {requiresAuth: true}},
  {path: '/nuevo-cliente',name: 'crearEmpresa',component: CrearEmpresaView, meta: {requiresAuth: true}},
  {path: '/nuevo-tipo',name: 'crearTipo',component: CrearTipoView, meta: {requiresAuth: true}},
  {path: '/nuevo-operador',name: 'crearUsuario',component: CrearUsuarioView, meta: {requiresAuth: true}},
  {path: '/nuevo-usuario',name: 'crearCliente',component: CrearClienteView, meta: {requiresAuth: true}},
  {path: '/nuevo-sistema',name: 'crearSistemas',component: CrearSistemaView, meta: {requiresAuth: true}},
  {path: '/nuevo-ticket',name: 'crearTicket',component: CrearTicketView, meta: {requiresAuth: true}},
  {path: '/nuevo-estatus',name: 'crearEstatus',component: CrearEstatusView, meta: {requiresAuth: true}},
  {path: '/nuevo-rol',name: 'crearRol',component: CrearRolView, meta: {requiresAuth: true}},
  {path: '/',name: 'home',component: AvancesView, meta: {requiresAuth: true}}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_SUBFOLDER,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(item=>item.meta.requiresAuth)){
    if(!store.state.token){
      next({name: 'login'})
    }
    
    try{
      jwt_decode(store.state.token)
    }catch(error){
      next({name: 'login'})
    }
    
    if(jwt_decode(store.state.token).exp*1000 <= new Date().getTime()){
      next({name: 'login'})
    }
    next()
  }else{
    next()
  }
})

export default router
