<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                    <h2 class="mb-0">Tickets</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                        <div class="d-flex">
                            <router-link to="/nuevo-ticket" class="btn btn-primary w-100 text-nowrap">Nuevo Ticket</router-link>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="card" id="table" >
                                <div class="card-header">
                                    <div class="row align-items-center">
                                        <div class="col-1 d-flex">
                                            <div class="form-check d-flex">
                                                <input class="form-check-input select-cbox" type="checkbox" v-model="allSelected" @click="selectAll()" >
                                            </div>
                                        
                                            <div class="btn-group dropend mb-2">
                                                <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">...</button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="#" @click="archivar()" >Archivar</a>
                                                    <div class="dropdown-divider">Mostrar</div>
                                                    <a class="dropdown-item" href="#" @click="getData()">Ver solo tickets</a>
                                                    <a class="dropdown-item" href="#" @click="getDataTodos()">Ver todos los tickets</a>
                                                    <a class="dropdown-item" href="#" @click="getDataArchivados()">Ver solo archivados</a>
                                                </div>
                                            </div>                                        
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group input-group-flush input-group-merge input-group-reverse">
                                                <span class="input-group-text search"><MagnifyIcon /></span>
                                                <input class="form-control list-search" v-model="filtro" type="buscar" placeholder="Buscar..">
                                            </div>                                           
                                        </div>
                                        <div class="col-3">
                                            <div class="form-floating">
                                                <select class="form-select" v-model="estatusSelected" >
                                                    <option v-for="(est,e) in estatus" :key="e" :value="est.id">{{est.nombre}}</option>
                                                    <option value="*">Todos</option>
                                                </select>
                                                <label for="floatingSelectTask">Mostrar</label>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-floating">
                                                <select class="form-select" v-model="perPage" >
                                                    <option value="20">20</option>
                                                    <option value="50">50</option>
                                                    <option value="150">150</option>
                                                    <option value="200">200</option>
                                                    <option value="250">250</option>
                                                    
                                                </select>
                                                <label for="floatingSelectTask">Tickets x página</label>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                <div>
                                    <table class="table table-sm fs--1 mb-0">
                                        <paginate 
                                            tag="tbody"
                                            name="tickets"
                                            ref="tickets"
                                            :per="perPage"
                                            class=""
                                            :list="filteredTickets">
                                                <tr v-for="(ticket,x) in paginated('tickets')" :key="x" class="ticket-row" @dblclick="gotopage(ticket)" >
                                                    <td>
                                                        <div class="d-flex align-middle">
                                                            <div class="ps-3">
                                                                <div class="form-check">
                                                                    <input class="form-check-input select-cbox" type="checkbox" v-model="selectedIds" @click="select()" :value="ticket.id">
                                                                </div>
                                                            </div>
                                                            <div class="pe-3 ps-3">{{ticket.id | leadingZeros(5)}} <FolderOutlineIcon v-tooltip="'Archivado'" v-if="ticket.archivado > 0" /> </div>
                                                            <div v-tooltip="ticket.estatus">
                                                                <Component :is="ticket.estatus_icono+'Icon'" class="d-inline-flex" :class="['text-'+ticket.estatus_color]" style="height:16px;width:16px;" />
                                                            </div>
                                                            <div class="pe-3 ps-3">{{formato(ticket.createdAt,'DD-MMM-YYYY')}}</div>
                                                            <div class="pe-3 flex-fill ticket-desc"><span class="text-nowrap overflow-hidden">{{ticket.descripcion }}</span></div>
                                                            <div  class="pe-3" v-tooltip="ticket.prioridad">
                                                                <span v-if="ticket.prioridad_id == 4" data-bs-toggle="tooltip" data-bs-placement="right" :title="ticket.prioridad" >
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning d-inline-flex"  />
                                                                    <StarOutlineIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarOutlineIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarOutlineIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                </span>
                                                                <span v-if="ticket.prioridad_id == 3" data-bs-toggle="tooltip" data-bs-placement="right" :title="ticket.prioridad" >
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarOutlineIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarOutlineIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                </span>
                                                                <span v-if="ticket.prioridad_id == 2" data-bs-toggle="tooltip" data-bs-placement="right" :title="ticket.prioridad" >
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                    <StarOutlineIcon style="height:12px;width:12px;" class="text-warning d-inline-flex" />
                                                                </span>
                                                                <span v-if="ticket.prioridad_id == 1" data-bs-toggle="tooltip" data-bs-placement="right" :title="ticket.prioridad" >
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning  d-inline-flex" />
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning  d-inline-flex" />
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning  d-inline-flex" />
                                                                    <StarIcon style="height:12px;width:12px;" class="text-warning  d-inline-flex" />
                                                                </span>
                                                            </div>
                                                            <div class=" align-middle gx-1">
                                                                <span v-if="ticket.tipo_id > 0" class="badge badge-iasc fs--2 badge-iasc-secondary"  @click="filtro += ' '+ticket.tipo"  v-tooltip="'Tipo'"><span class="badge-label">{{ticket.tipo}}</span></span>
                                                                <span v-if="ticket.categoria_id > 0" class="badge badge-iasc fs--2 badge-iasc-secondary"  @click="filtro += ' '+ticket.categoria" v-tooltip="'Categoría'"><span class="badge-label">{{ticket.categoria}}</span></span>
                                                                <span v-if="ticket.cliente_id > 0" class="badge badge-iasc fs--2 badge-iasc-secondary"  @click="filtro += ' '+ticket.cliente" v-tooltip="'Usuario'"><span class="badge-label">{{ticket.cliente}}</span></span>
                                                                <span v-if="ticket.empresa_id > 0" class="badge badge-iasc fs--2 badge-iasc-secondary"  @click="filtro += ' '+ticket.empresa" v-tooltip="'Cliente'"><span class="badge-label">{{ticket.empresa}}</span></span>
                                                                <span v-if="ticket.usuario_id > 0" class="badge badge-iasc fs--2 badge-iasc-secondary"  @click="filtro += ' '+ticket.usuario" v-tooltip="'Operador'"><span class="badge-label">{{ticket.agente}}</span></span>
                                                                <span v-if="ticket.sistema_id > 0" class="badge badge-iasc fs--2 badge-iasc-secondary"  @click="filtro += ' '+ticket.sistema" v-tooltip="'Sistema'"><span class="badge-label">{{ticket.sistema}}</span></span>
                                                            </div>
                                                            <div class="white-space-nowrap text-end pe-0">
                                                                <div class="font-sans-serif btn-reveal-trigger position-static">
                                                                    <button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><svg class="svg-inline--fa fa-ellipsis fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path></svg><!-- <span class="fas fa-ellipsis-h fs--2"></span> Font Awesome fontawesome.com --></button>
                                                                    <div class="dropdown-menu dropdown-menu-end py-2">
                                                                        <router-link class="dropdown-item" :to="'/ticket/'+ticket.id">Abrir</router-link>
                                                                        <div class="dropdown-divider"></div>
                                                                        <a href="#" class="dropdown-item text-danger" @click="borrar('tickets',ticket.id)">Borrar</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                        </paginate>
                                    </table>
                                </div>
                                <div v-if="$refs.tickets" class="d-flex flex-between-center pt-3">
                                    <div class="row align-items-center justify-content-between py-2 pe-0 fs--1">
                                        <div class="col-auto d-flex">
                                            <p class="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900" data-list-info="data-list-info">
                                                <span class="text-600" v-if="$refs.tickets"> Mostrando </span> {{$refs.tickets.pageItemsCount}}
                                            </p>
                                            <a v-if="filteredTickets.length > perPage" class="fw-semi-bold list-pagination-all" @click="perPage = filteredTickets.length" href="#!">Ver todo<svg class="svg-inline--fa fa-angle-right ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="" style="transform-origin: 0.25em 0.5625em;"><g transform="translate(128 256)"><g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)"><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" transform="translate(-128 -256)"></path></g></g></svg><!-- <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span> Font Awesome fontawesome.com --></a>
                                            <a v-if="filteredTickets.length > perPage" class="fw-semi-bold d-none list-pagination-all" href="#!" @click="perPage = 20" >Ver menos<svg class="svg-inline--fa fa-angle-right ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="" style="transform-origin: 0.25em 0.5625em;"><g transform="translate(128 256)"><g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)"><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" transform="translate(-128 -256)"></path></g></g></svg><!-- <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span> Font Awesome fontawesome.com --></a>
                                        </div>
                                        <div class="col-auto d-flex">
                                            <button class="page-link " @click="goToPrev()" data-list-pagination="prev" >
                                                <svg class="svg-inline--fa fa-chevron-left" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"></path></svg><!-- <span class="fas fa-chevron-left"></span> Font Awesome fontawesome.com -->
                                            </button>

                                            <paginate-links @change="onLangsPageChange" 
                                                v-if="$refs.tickets" 
                                                for="tickets" 
                                                :limit="10"
                                                :classes="{'ul': ['mb-0','list-pagination','pagination','pagination-tabs','card-pagination'],'a': ['page']}"></paginate-links>

                                            <button  class="page-link pe-0" @click="goToNext()" data-list-pagination="next">
                                                <svg class="svg-inline--fa fa-chevron-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg="">
                                                    <path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  import moment from 'moment'
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
  import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';
  import ProgressClockIcon from 'vue-material-design-icons/ProgressClock.vue';
  import ProgressCheckIcon from 'vue-material-design-icons/ProgressCheck.vue';
  import CircleEditOutlineIcon from 'vue-material-design-icons/CircleEditOutline.vue';
  import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
  import CancelIcon from 'vue-material-design-icons/Cancel.vue';
  import StarCheckIcon from 'vue-material-design-icons/StarCheck.vue';
  import StarIcon from 'vue-material-design-icons/Star.vue';
  import StarOutlineIcon from 'vue-material-design-icons/StarOutline.vue';
  import FolderOutlineIcon from 'vue-material-design-icons/FolderOutline.vue';
  import handleGlobals from '@/mixins/handleGlobals';

  export default {
    name: 'TicketsView',
    mixins:[handleGlobals],
    components:{SideBar,moment,TopBar,FolderOutlineIcon,MagnifyIcon,StarIcon,AlertCircleOutlineIcon,StarOutlineIcon,CircleEditOutlineIcon,ProgressClockIcon,ProgressCheckIcon,CheckCircleOutlineIcon,CancelIcon,StarCheckIcon},
    data(){
        return{
            tickets:[],
            filtro: localStorage.getItem('filtro') || '',
            paginate: ['tickets'],
            currentPage:1,
            perPage:20,
            estatusSelected: 1,
            estatus: [],
            selectedIds:[],
            selected: [],
            allSelected: false,
        }
    },
    created(){
        this.getEstatus()
        this.getData()
    },
    computed:{
        filteredTickets(){
            let res = this.tickets.filter((e)=>{
                return (this.estatusSelected == '*' ? e : e.estatus_id == this.estatusSelected)
            }).filter((e)=>{
                if(this.filtro != ''){
                    return ((e.titulo) ? e.titulo.toLowerCase().trim().includes(this.filtro.toLowerCase().trim())  : '' ) || 
                           ((e.descripcion) ? e.descripcion.toLowerCase().trim().includes(this.filtro.toLowerCase().trim())  : '') || 
                           ((e.createdAt) ? e.createdAt.toLowerCase().trim().includes(this.filtro.toLowerCase().trim())  : '') || 
                           ((e.tipo) ? e.tipo.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.categoria) ? e.categoria.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.usuario) ? e.usuario.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.empresa) ? e.empresa.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.cliente) ? e.cliente.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.sistema) ? e.sistema.toLowerCase().trim().includes(this.filtro.toLowerCase().trim()) : '') ||
                           ((e.id) ? e.id.toString().includes(this.filtro.toLowerCase()) : '') 
                }else{
                    this.$store.dispatch('saveFiltro','')
                    return e
                }
            })
            this.currentPage = 1
            return res
        }
    },
    methods:{
        formato(fecha,formato){
            return moment(fecha).format(formato)
        },
        onLangsPageChange(toPage,fromPage){
            this.currentPage = toPage
        },
        goToPrev(){
            if(this.currentPage > 1){
                this.currentPage = this.currentPage - 1
                this.$refs.tickets.goToPage(this.currentPage)
            }else{
                this.$refs.tickets.goToPage(0)
            }
        },
        gotopage(ticket){
            this.$router.push({name: 'verTicket', params: {id:ticket.id}})
        },
        goToNext(){
            if(this.currentPage <= Math.ceil( this.filteredTickets.length/this.perPage)){
                this.currentPage = this.currentPage + 1
                this.$refs.tickets.goToPage(this.currentPage)
            }else{
                this.$refs.tickets.goToPage(Math.ceil( this.filteredTickets.length/this.perPage))
            }
        },
        getData(){
            axios.get(this.$url+'/tickets-admin',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.tickets = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataTodos(){
            axios.get(this.$url+'/tickets-w-admin-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                console.log(res.data)
                this.tickets = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getDataArchivados(){
            axios.get(this.$url+'/tickets-admin-archive',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.tickets = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getEstatus(){
             axios.get(this.$url+'/estatuses',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.estatus = res.data
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        selectAll() {
            this.selectedIds = [];
            const pagelisted = this.paginated('tickets')
            if(!this.allSelected){
                for(var i=0;i < pagelisted.length;i++) {
                    this.selectedIds.push(pagelisted[i].id.toString());
                }
            }
        },
        select() {
            this.allSelected = false;
        },
        archivar(){
            const data = {
                ticket_ids : this.selectedIds
            }
            axios.post(this.$url+'/ticket-archivar',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message == 'done'){
                    this.allSelected = false
                    this.selectedIds = []
                    this.getData()
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        eliminar(){
            const data = {
                ticket_ids: this.selectedIds
            }
            axios.get(this.$url+'/tickets/delete-selected',data,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message == 'done'){
                    this.allSelected = false
                    this.getData()
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
    }
  }
  </script>
  