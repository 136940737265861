import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            
        }
    },
    methods:{
        logout(){
            this.$store.dispatch('logout')
            this.$router.push({name: 'login'})
            console.log('logout')
        },
        borrar(pathName,id){
            axios.put(this.$url+'/'+pathName+'/delete/'+id,{},{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data == true){
                    this.getData()
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'Error al intentar eliminar el registro',
                        type: 'danger'
                    });
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        formato(fecha,formato){
            if(fecha){
                console.log(fecha)
                return moment(fecha).format(formato)
            }else{
                return ' - '
            }
        }
    }
}