<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
           
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                    <h2 class="mb-0">Clientes</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                        <div class="d-flex">
                            <router-link to="/nuevo-cliente" class="btn btn-primary w-100 text-nowrap">Nuevo Cliente</router-link>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="card" id="table">
                                <div class="card-header">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <form>
                                                <div class="input-group input-group-flush input-group-merge input-group-reverse">
                                                    <span class="input-group-text search"><MagnifyIcon /></span>
                                                    <input class="form-control list-search" v-model="filtro" type="buscar" placeholder="Buscar..">
                                                </div>
                                            </form>
                                        </div>
                                    </div> 
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-sm fs--1 mb-0">
                                        <thead>
                                        <tr>
                                            <th class="sort border-top" data-sort="item-numero">Id</th>
                                            <th class="sort border-top" data-sort="item-nombre">Nombre</th>
                                            <th class="sort border-top" data-sort="item-nombre">RFC</th>
                                            <th class="sort border-top" data-sort="item-activado">Activo</th>
                                            <th class="sort border-top" data-sort="item-bloqueado">Bloqueado</th>
                                            <th class="sort text-end align-middle pe-0 border-top" scope="col">
                                                <span class="nav-link-icon"><span data-feather="cog"></span></span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <paginate 
                                            tag="tbody"
                                            name="empresas"
                                            ref="empresas"
                                            :per="perPage"
                                            class=""
                                            :list="filteredEmpresas">
                                                <tr v-for="(empresa,x) in paginated('empresas')" :key="x">
                                                    <td class="item-numero align-middle">{{empresa.id | leadingZeros(3)}}</td>
                                                    <td class="item-nombre align-middle">{{empresa.nombre}}</td>
                                                    <td class="item-nombre align-middle text-uppercase">{{empresa.rfc}}</td>
                                                    <td class="item-activado align-middle">
                                                        <span class="badge badge-iasc badge-iasc-success" v-if="empresa.activado">Activo</span>
                                                        <span class="badge badge-iasc badge-iasc-danger" v-else>Inactivo</span>
                                                    </td>
                                                    <td class="item-bloqueado align-middle">
                                                        <span class="badge badge-iasc badge-iasc-danger" v-if="empresa.bloqueado == 1">Bloqueado</span>
                                                        <span class="badge badge-iasc badge-iasc-success" v-else>Desbloqueado</span>
                                                    </td>
                                                    <td class="align-middle white-space-nowrap text-end pe-0">
                                                    <div class="font-sans-serif btn-reveal-trigger position-static">
                                                        <button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><svg class="svg-inline--fa fa-ellipsis fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path></svg><!-- <span class="fas fa-ellipsis-h fs--2"></span> Font Awesome fontawesome.com --></button>
                                                        <div class="dropdown-menu dropdown-menu-end py-2">
                                                            <router-link class="dropdown-item" :to="'/cliente/'+empresa.id">Abrir</router-link>
                                                        <div class="dropdown-divider"></div>
                                                        <a href="#" class="dropdown-item text-danger" @click="borrar('empresas',empresa.id)">Borrar</a>

                                                        </div>
                                                    </div>
                                                    </td>
                                                </tr>
                                        </paginate>
                                    </table>
                                </div>
                                <div v-if="$refs.empresas" class="d-flex flex-between-center pt-3">
                                    <div class="row align-items-center justify-content-between py-2 pe-0 fs--1">
                                        <div class="col-auto d-flex">
                                            <p class="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900" data-list-info="data-list-info">
                                                <span class="text-600"  v-if="$refs.empresas"> Mostrando </span> {{$refs.empresas.pageItemsCount}}
                                            </p>
                                            <a v-if="filteredEmpresas.length > perPage" class="fw-semi-bold" @click="perPage = filteredEmpresas.length" href="#!" >Ver todo<svg class="svg-inline--fa fa-angle-right ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="" style="transform-origin: 0.25em 0.5625em;"><g transform="translate(128 256)"><g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)"><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" transform="translate(-128 -256)"></path></g></g></svg><!-- <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span> Font Awesome fontawesome.com --></a>
                                            <a v-if="filteredEmpresas.length > perPage" class="fw-semi-bold d-none" href="#!" @click="perPage = 20" >Ver menos<svg class="svg-inline--fa fa-angle-right ms-1" data-fa-transform="down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="" style="transform-origin: 0.25em 0.5625em;"><g transform="translate(128 256)"><g transform="translate(0, 32)  scale(1, 1)  rotate(0 0 0)"><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z" transform="translate(-128 -256)"></path></g></g></svg><!-- <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span> Font Awesome fontawesome.com --></a>
                                        </div>
                                        <div class="col-auto d-flex">
                                            <button class="page-link " @click="goToPrev()" data-list-pagination="prev" >
                                                <svg class="svg-inline--fa fa-chevron-left" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"></path></svg><!-- <span class="fas fa-chevron-left"></span> Font Awesome fontawesome.com -->
                                            </button>
                                            <paginate-links @change="onLangsPageChange" 
                                                v-if="$refs.empresas" 
                                                for="empresas" 
                                                :limit="10"
                                                :classes="{'ul': ['mb-0','list-pagination','pagination','pagination-tabs','card-pagination'],'a': ['page']}"></paginate-links>

                                            <button class="page-link pe-0" @click="goToNext()" data-list-pagination="next">
                                                <svg class="svg-inline--fa fa-chevron-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path></svg><!-- <span class="fas fa-chevron-right"></span> Font Awesome fontawesome.com -->
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
  import handleGlobals from '@/mixins/handleGlobals';

  export default {
    name: 'EmpresasView',
    components:{SideBar,TopBar,MagnifyIcon},
    mixins:[handleGlobals],

    data(){
        return{
            empresas:[],
            filtro:'',
            paginate:['empresas'],
            currentPage:1,
            perPage:20
        }
    },
    created(){
        this.getData()
    },
    computed:{
        filteredEmpresas(){
            let res = this.empresas.filter((e)=>{
                if(this.filtro != ''){
                    return ((e.nombre) ? e.nombre.toLowerCase().includes(this.filtro.toLowerCase()) : '') || 
                           ((e.numero) ? e.numero.toLowerCase().includes(this.filtro.toLowerCase())  : '') 
                }else{
                    return e
                }
            })
            return res
        }
    },
    methods:{
        onLangsPageChange(toPage,fromPage){
            this.currentPage = toPage
        },
        goToPrev(){
            if(this.currentPage > 1){
                this.currentPage = this.currentPage - 1
                this.$refs.empresas.goToPage(this.currentPage)
            }else{
                this.$refs.empresas.goToPage(0)
            }
        },
        goToNext(){
            if(this.currentPage <= Math.ceil( this.filteredEmpresas.length/this.perPage)){
                this.currentPage = this.currentPage + 1
                this.$refs.empresas.goToPage(this.currentPage)
            }else{
                this.$refs.empresas.goToPage(Math.ceil( this.filteredEmpresas.length/this.perPage))
            }
        },
        getData(){
             axios.get(this.$url+'/empresas',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.empresas = res.data

            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        }
    }
  }
  </script>
  