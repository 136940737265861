<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="validar()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                        <h2 class="mb-0" v-if="modo == 'Creando'">Nuevo Prioridad</h2>
                        <h2 class="mb-0" v-else >Modificando Prioridad</h2>

                        <div class="text-end">
                            <router-link to="/prioridades" class="btn btn-outline-primary text-nowrap">Regresar</router-link>
                        </div>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary w-100 text-nowrap">Publicar Prioridad</button>
                        </div>
                    </div>
                    <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input class="form-control" v-model="prioridad.nombre" type="text" placeholder="nombre de prioridad de ticket" />
                                    <label for="floatingEventInput">Nombre</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  
  export default {
    name: 'CrearPrioridadView',
    components:{SideBar,TopBar},
    data(){
        return{
            prioridad:{},
            msg_err:'',
            modo: (this.$route.params.id > 0 ? 'Modificando' : 'Creando'),
        }
    },
    created(){
        if(this.modo == 'Modificando') this.getData()
    },
    methods:{
        validar(){
            if(!this.prioridad.nombre){
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'Debe asignar un nombre a la prioridad',
                        type: 'error'
                    });
            }else{
                this.setPrioridad()
            }
        },
        setPrioridad(){
            axios.post(this.$url+(this.modo == 'Creando' ? '/prioridad' : '/prioridad/'+this.$route.params.id),this.prioridad,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
              
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.$router.push({name: 'prioridades'})
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getData(){
            axios.get(this.$url+'/prioridad/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                   
                    this.prioridad = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        }
    }
  }
  </script>
  