<template>
    <div>
        <div v-if="profile.nombre == 'IASC'" class="avatar" :class="['avatar-'+(size != '' ? size : 'l')]">
            <img class="rounded-circle " src="@/assets/img/logo-user.jpg" alt="IASC">
        </div>
        <div v-else-if="!profile.foto" class="avatar" :class="['avatar-'+(size != '' ? size : 'l')]">
            <div class="avatar-name rounded-circle"><span>{{ (!profile.nombre  ? '' : profile.nombre.charAt(0)) + (!profile.apellidos ? '' : profile.apellidos.charAt(0))  }}</span></div>
        </div>
        <div v-else class="avatar" :class="['avatar-'+(size != '' ? size : 'l')]">
            <img class="rounded-circle " :src="$url+'/'+ profile.foto" alt="">
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ProfilePhoto',
    props:['usuario','size'],
    computed:{
        profile(){
            return this.$props.usuario
        }
    }
}
</script>