<template>
  <nav class="navbar navbar-vertical navbar-expand-lg" >
    <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
     
      <div class="navbar-vertical-content">
        <ul class="navbar-nav flex-column" id="navbarVerticalNav">
          <li class="nav-item">
            <!-- parent pages-->
            <div class="nav-item-wrapper">
             <router-link class="nav-link label-1" active-class="active" to="/avances" role="button" data-bs-toggle="" aria-expanded="false">
               <div class="d-flex align-items-center"><span class="nav-link-icon"><ChartTimelineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Avances</span></span>
               </div>
             </router-link>
           </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/tickets" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><TagMultipleOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Tickets</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/avisos" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><BellPlusOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Avisos</span></span>
                </div>
              </router-link>
            </div>
            
          </li>
          <li class="nav-item">
            <!-- label-->
            <p class="navbar-vertical-label">Archivos</p>
            <hr class="navbar-vertical-line" />
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/sistemas" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><MonitorDashboardIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Sistemas</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/operadores" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><AccountGroupOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Operadores</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/usuarios" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><AccountGroupIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Usuarios</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/clientes" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><OfficeBuildingIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Clientes</span></span>
                </div>
              </router-link>
            </div>
            
           
          </li>
          <li class="nav-item">
            <!-- label-->
            <p class="navbar-vertical-label">Operaciones</p>
            <hr class="navbar-vertical-line" />
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/roles" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><AccountKeyIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Roles</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/categorias" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><ShapeOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Categorías</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/tipos" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><FormatListBulletedTypeIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Tipos</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/prioridades" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><StarSettingsOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Prioridades</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/estatus" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><StateMachineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Estatus</span></span>
                </div>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="navbar-vertical-footer">
      <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
        <span class="uil uil-left-arrow-to-left fs-0"><ArrowCollapseLeftIcon /></span>
        <span class="uil uil-arrow-from-right fs-0"><ArrowCollapseRightIcon /></span>
        <span class="navbar-vertical-footer-text ms-2">Colapsar</span>
      </button>
    </div>
  </nav>
</template>

<script>
import ChartPieOutlineIcon from 'vue-material-design-icons/ChartPieOutline.vue';
import TagMultipleOutlineIcon from 'vue-material-design-icons/TagMultipleOutline.vue';
import MonitorDashboardIcon from 'vue-material-design-icons/MonitorDashboard.vue';
import AccountGroupOutlineIcon from 'vue-material-design-icons/AccountGroupOutline.vue';
import OfficeBuildingIcon from 'vue-material-design-icons/OfficeBuilding.vue';
import ChartTimelineIcon from 'vue-material-design-icons/ChartTimeline.vue';
import AccountKeyIcon from 'vue-material-design-icons/AccountKey.vue';
import ShapeOutlineIcon from 'vue-material-design-icons/ShapeOutline.vue';
import FormatListBulletedTypeIcon from 'vue-material-design-icons/FormatListBulletedType.vue';
import StarSettingsOutlineIcon from 'vue-material-design-icons/StarSettingsOutline.vue';
import StateMachineIcon from 'vue-material-design-icons/StateMachine.vue';
import ArrowCollapseRightIcon from 'vue-material-design-icons/ArrowCollapseRight.vue';
import ArrowCollapseLeftIcon from 'vue-material-design-icons/ArrowCollapseLeft.vue';
import AccountGroupIcon from 'vue-material-design-icons/AccountGroup.vue';
import BellPlusOutlineIcon from 'vue-material-design-icons/BellPlusOutline.vue';

export default {
    name: 'SideBarNav',
    components:{ChartPieOutlineIcon,BellPlusOutlineIcon,OfficeBuildingIcon,AccountKeyIcon,ShapeOutlineIcon,
                ArrowCollapseLeftIcon,ArrowCollapseRightIcon,StarSettingsOutlineIcon,
                StateMachineIcon,FormatListBulletedTypeIcon,TagMultipleOutlineIcon,
                MonitorDashboardIcon,AccountGroupOutlineIcon,ChartTimelineIcon,AccountGroupIcon},
    
  }
</script>