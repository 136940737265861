<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="validar()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-9">
                        <h2 class="mb-0" v-if="modo == 'Creando'">Nuevo Usuario</h2>
                        <h2 class="mb-0" v-else >Modificando Usuario</h2>

                        <div class="text-end">
                            <router-link to="/usuarios" class="btn btn-outline-primary text-nowrap">Regresar</router-link>
                        </div>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-3">
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary w-100 text-nowrap">Publicar Usuario</button>
                        </div>
                    </div>
                   
                </div>
                <div class="row g-5">
                    <div class="col-xl-8">
                        <div class="row gx-3 gy-4">
                            
                            <div class="col-sm-12 col-md-5">
                                <div class="form-floating">
                                    <input class="form-control" v-model="cliente.nombre" type="text" />
                                    <label >Nombre</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <div class="form-floating">
                                    <input class="form-control" v-model="cliente.apellidos" type="text" />
                                    <label >Apellidos</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="cliente.email" type="text"  />
                                    <label >Email</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="cliente.new_password" type="password"  />
                                    <label >Nueva Contraseña</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <select class="form-select" v-model="cliente.empresa_id" >
                                        <option disabled selected="selected">Selecciona...</option>
                                        <option v-for="(dato,x) in empresas" :key="x" :value="dato.id">{{dato.nombre}}</option>
                                    </select>
                                    <label for="floatingSelectTask">Empresa</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="border-bottom mb-5">
                            <div class="form-floating">
                                <select  class="form-control" v-model="cliente.activado">
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </select>
                                <label for="floatingEventInput">Estatus</label>
                            </div>
                            
                        </div>
                        <div class="border-bottom mb-6">
                            <div class="form-floating">
                                <select  class="form-control" v-model="cliente.bloqueado">
                                    <option value="1">Bloqueado</option>
                                    <option value="0">Desbloqueado</option>
                                </select>
                                <label for="floatingEventInput">Actividad</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  
  export default {
    name: 'CrearClienteView',
    components:{SideBar,TopBar},
    data(){
        return{
            cliente:{},
            empresas:{},
            modo: (this.$route.params.id > 0 ? 'Modificando' : 'Creando'),
        }
    },
    created(){
        this.getReqs()
        if(this.modo == 'Modificando') this.getData()
    },
    methods:{
        getReqs(){
            axios.get(this.$url+'/empresas',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                 
                }else{
                    this.empresas = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                });
                 
            })
        },
        validar(){
            if(this.modo == 'Creando' && !this.cliente.nombre && !this.cliente.email && !this.cliente.new_password ){
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'No se puede agregar un usuario sin nombre y sin email y sin contraseña',
                    type: 'error'
                });
            }else if(this.modo == 'Modificando' && !this.cliente.nombre && !this.cliente.email ){
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'No se puede modificar un usuario sin nombre y sin email ',
                    type: 'error'
                });
            }else{
                this.setcliente()
            }
        },
        setcliente(){
            axios.post(this.$url+(this.modo == 'Creando' ? '/cliente' : '/cliente/'+this.$route.params.id),this.cliente,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    this.$router.push({name: 'usuarios'})
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                });
              
            })
        },
        getData(){
            axios.get(this.$url+'/cliente/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: res.data.message,
                    type: 'error'
                });
                }else{
                    this.cliente = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: err.response.data.message,
                    type: 'error'
                });
            })
        }
    }
  }
  </script>
  