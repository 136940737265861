<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="validar()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Creando'">Nuevo Rol</h2>
                        <h2 class="mb-0" v-else >Modificando Rol</h2>
                        <div class="text-end">
                            <router-link to="/roles" class="btn btn-outline-primary text-nowrap">Regresar</router-link>
                        </div>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                    <div class="d-flex">
                        <button  type="submit" class="btn btn-primary  w-100 text-nowrap">Publicar Rol</button>
                    </div>
                    </div>
                    <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input class="form-control" v-model="rol.nombre" type="text" placeholder="nombre del Rol" />
                                    <label for="floatingEventInput">Nombre</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  
  export default {
    name: 'CrearRolView',
    components:{SideBar,TopBar},
    data(){
        return{
            rol:{},
            msg_err:'',
            modo: (this.$route.params.id > 0 ? 'Modificando' : 'Creando'),
        }
    },
    created(){
        if(this.modo == 'Modificando') this.getData()
    },
    methods:{
        validar(){
            if(!this.rol.nombre){
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'Debe agregar un nombre al Rol',
                        type: 'error'
                    });
            }else{
                this.setRol()
            }
        },
        setRol(){
            axios.post(this.$url+(this.modo == 'Creando' ? '/rol' : '/rol/'+this.$route.params.id),this.rol,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                    let num=528115779977;
                    let msg= 'nuevo rol';
                    let name= 'Marina'
                    window.open(`https://api.whatsapp.com/send?phone=${num}&text=Hi${name}`, '_blank');
                    this.$router.push({name: 'roles'})
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        },
        getData(){
            axios.get(this.$url+'/rol/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                if(res.data.message){
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: res.data.message,
                        type: 'error'
                    });
                }else{
                   
                    this.rol = res.data
                }
            }).catch((err)=>{
                if(err.response) {
                    if(err.response.status == 500) this.logout()
                }
                this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: err.response.data.message,
                        type: 'error'
                    });
            })
        }
    }
  }
  </script>
  